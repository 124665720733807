<template>
  <v-app>
    <div class="padding-card-c grey">     
      <p class="title-user-scr-b" style="padding-top: 50px"> Tickets</p>
      <v-col v-for="(item, i) in shownCards" :key="i" cols="12">
        <v-card color="white">
          <div class="d-flex flex-no-wrap justify-space-between">
            <v-list-item>
              <v-list-item-avatar>
                <img alt="" :src="imageDefaul" />
              </v-list-item-avatar>
              <v-list-item-content style="margin-left: 15px">
                <v-list-item-title class="title-text-info">Ticket {{
                  item.id
                }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  item.user.username
                }}</v-list-item-subtitle>
                <span style="font-size: 12px"> {{ $dayjs(item.dateOpened).format('lll') }} </span>
                
              </v-list-item-content>

              <v-list-item-action>
                <v-row>
                  <v-col>
                    <div style="padding-top: 10px">                      
                      <span v-if="item.dateClosed" style="font-weight: 800; color:#f00">Cerrado </span>
                      <span v-else style="font-weight: 800;">abierto</span>
                    </div>
                  </v-col>                  
                  <v-col>
                    <div style="padding-top: 10px">
                      <span style="font-weight: 800;">{{ item.priority }}</span>
                    </div>
                  </v-col>
                  <v-col>
                    <div style="padding-top: 10px">
                      <v-btn  color="primary" small fab dark  :to="{ name: 'tickethistory', params: { ticketId: item.id} }"  >
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>                 
                    </div>
                  </v-col>
                </v-row>  
              </v-list-item-action>
            </v-list-item>
          </div>
        </v-card>
      </v-col>
       <div class="text-center">
      <v-pagination v-model="page" :length="length"></v-pagination>
    </div>
    </div>
  </v-app>
</template>

<script>

import adminTicketService from '@/services/admin/tickets';

export default {
  created() {
    this.getTickets();   
  },
  methods: {
    getTickets() {
      adminTicketService.fetch().then((response) => {
        this.tickets = response.data;
        console.log(this.tickets);
      });
    },
  },
  computed: {
    user() {
      console.log(this.$store.getters['session/user']);
      return this.$store.getters['session/user'];
    },
    imageDefaul() {
      return require('@/assets/images/avatar_dz.jpg');
    },
    length() {
      return Math.ceil(this.tickets.length / 10);
    },
    shownCards() {
      const { page, tickets } = this;
      const number = 10; // Numero de elementos a mostrar por página
      return tickets.slice((page - 1) * number, page * number);
    },
  },
  data() {
    return {   
      page: 1,  
      tickets: [],
    };
  },


};
</script>

<style lang="scss" scoped>
@import './src/assets/style.scss';
</style>