import { apiClient } from '../index';


/**
 * [Regresa todos los tickets]
 * @return {[type]}          [tickets]
 */
function fetch() {
    return apiClient.get('/v1/tickets');
  }

  /**
 * [Regresa el ticket by Id]
 * @return {[type]}          [ticket]
 */
function fetchTicket(id) {
    return apiClient.get('/v1/tickets/ticket/' + id);
  }


  /**
 * [Agrega un mensaje al hilo del ticket]
 * @return {[type]}          [mensaje]
 */
function addTicketMessage (id, msg) {
    return apiClient.post('/v1/tickets/addTicketMessage/', {
        ticket_id: id,
        message: msg
    });
  }

  /**
 * [cambiar la prioridad del ticket]
 * @return {[type]}          [priority]
 */
function setPriority (id, priority) {
  console.log(id);
  return apiClient.post('/v1/tickets/priority/', {
      ticketId: id,
      priority: priority
  });
}

 /**
 * [cerrar ticket]
 * @return {[type]}          [ticket]
 */
 function closeTicket (ticketId) {
  return apiClient.get('/v1/tickets/close/' + ticketId);
}

export default { fetch, fetchTicket, addTicketMessage, setPriority, closeTicket };